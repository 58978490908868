<template>
  <div class="app" v-if="!this.loading">
    <Navbar v-bind:showProfile="true" v-bind:profiles="profiles" v-bind:account="this.account" />

    <div class="content">

      <Menu v-bind:disabled="true" v-bind:hide="true" />

      <div class="main-view">
        <div class="row-wrapper full">
          <div class="widget">
            <div class="widget-title background">Profile</div>
            <div class="widget-content">
              <table class="widget-table">
                <tr>
                  <th>Email</th>
                  <td>{{this.account.email}}</td>
                </tr>
                <tr>
                  <th>Username</th>
                  <td>{{this.account.auth.name}}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>

        <div class="row-wrapper half">
          <div class="widget">
            <div class="widget-content">
              <div class="widget-title">Subscription</div>
              <div class="widget-input">
                <input type="text" readonly="true" v-bind:value="this.account.subscription.plan" />
                <div class="widget-input-name">Current plan</div>
              </div>
              <div class="widget-info">
                <a href="#">Click here</a> to change or extend your plan.
              </div>
            </div>
          </div>

          <div class="widget">
            <div class="widget-content">
              <div class="widget-title">Change password</div>
              <div class="widget-input fixed-name-width">
                <input v-model="currentPassword" type="password" />
                <div class="widget-input-name">Current password</div>
              </div>
              <div class="placeholder"></div>
              <div class="widget-input fixed-name-width">
                <input v-model="newPassword" type="password" />
                <div class="widget-input-name">New password</div>
              </div>
              <div class="widget-input fixed-name-width">
                <input v-model="repeatPassword" type="password" />
                <div class="widget-input-name">Repeat password</div>
              </div>
              <div class="placeholder"></div>
              <button class="button-primary" v-bind:disabled="changingPassword" v-on:click="changePassword">Change</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="text-align: center; margin-top: 50px;" v-else>
    <div class="loader"></div>
  </div>
</template>

<script>
import Api from '../../scripts/api';
import Navbar from "../../components/global/navbar/Navbar";
import Menu from "../../components/global/navbar/Menu";

import Toast from '../../scripts/toast';

export default {
  components: { Navbar, Menu },
  data() {
      return {
        profiles: [
          
        ],
        account: undefined,
        loading: true,

        changingPassword: false,
        currentPassword: '',
        newPassword: '',
        repeatPassword: ''
      }
  },
  async created() {
    var data = (await Api.sync()).data;
    this.profiles = data.profiles;
    this.account = data.account;
    this.loading = false;
  },
  methods: {
    changePassword: async function() {
      if(!Api.validation.validatePassword(this.newPassword)) {
        return Toast.error('Password must be at least 6 characters.')
      }
      if(this.newPassword != this.repeatPassword) {
        return Toast.error('Passwords do not match.');
      }

      this.changingPassword = true;

      var result = await Api.changePassword(this.currentPassword, this.newPassword);
      if(result.data.success) {
        this.currentPassword = '';
        this.newPassword = '';
        this.repeatPassword = '';
        Toast.success('Changed password!');
      }
      this.changingPassword = false;
    } 
  }
};
</script>

<style scoped>
@import "../../assets/css/dashboard.css";
</style>