import Toastify from 'toastify-js';

let toast = {
    success: (message) => {
        return Toastify({
            text: message,
            duration: 3000, 
            newWindow: true,
            close: true,
            gravity: 'bottom', // `top` or `bottom`
            position: 'right', // `left`, `center` or `right`
            backgroundColor: "linear-gradient(124deg, rgba(43,203,115,1) 0%, rgba(13,68,139,1) 100%)",
            stopOnFocus: true, // Prevents dismissing of toast on hover
            onClick: function(){} // Callback after click
          }).showToast();
    },
    error: (message) => {
        return Toastify({
            text: message,
            duration: 3000, 
            newWindow: true,
            close: true,
            gravity: 'bottom', // `top` or `bottom`
            position: 'right', // `left`, `center` or `right`
            backgroundColor: "linear-gradient(124deg, rgba(203,43,43,1) 0%, rgba(13,68,139,1) 100%)",
            stopOnFocus: true, // Prevents dismissing of toast on hover
            onClick: function(){} // Callback after click
          }).showToast();
    }
}
export default toast;